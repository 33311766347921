import React, { useState } from "react";
import { addPackage } from "../../../Services/api";
import { withRouter } from "react-router-dom";

function AddNewPackage({ history }) {
  const [packages, setPackages] = useState([]);
  const [newPackage, setNewPackage] = useState({
    prize: "",
    months: "",
    specialPrize: "",
    user: "vendor", 
  });

  const handleInputChange = (e) => {
    setNewPackage({ ...newPackage, [e.target.name]: e.target.value });
  };

  const handleAddPackage = (e) => {
    e.preventDefault();

    addPackage(newPackage)
      .then((response) => {
        alert("Package created successfully!");
        setPackages((prevPackages) => [...prevPackages, response.data]);
        setNewPackage({
          prize: "",
          months: "",
          specialPrize: "",
          user: "vendor",
        });
      })
      .catch((error) => {
        console.error("Error creating package:", error);
        alert("Failed to create package.");
      });
  };

  return (
    <div className="container-fluid mt-5" style={{ marginTop: "50px" }}>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="card shadow w-100">
            <div className="card-body">
              <form onSubmit={handleAddPackage} className="w-100">
                <div className="form-group mb-3">
                  <label htmlFor="prize">Prize</label>
                  <input
                    type="text"
                    className="form-control"
                    id="prize"
                    name="prize"
                    value={newPackage.prize}
                    onChange={handleInputChange}
                    placeholder="Enter prize amount"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="months">Months</label>
                  <input
                    type="number"
                    className="form-control"
                    id="months"
                    name="months"
                    value={newPackage.months}
                    onChange={handleInputChange}
                    placeholder="Enter number of months"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="specialPrize">Special Prize</label>
                  <input
                    type="text"
                    className="form-control"
                    id="specialPrize"
                    name="specialPrize"
                    value={newPackage.specialPrize}
                    onChange={handleInputChange}
                    placeholder="Enter special prize amount"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="user">User Type</label>
                  <select
                    className="form-select"
                    id="user"
                    name="user"
                    value={newPackage.user}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="vendor">Vendor</option>
                    <option value="candidate">Candidate</option>
                    <option value="counsellor">Counsellor</option>
                    <option value="institute">Institute</option>
                  </select>
                </div>
                <div className="d-grid">
                  <button type="submit" className="btn btn-success">
                    Create Package
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AddNewPackage);
