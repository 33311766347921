import React from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { deleteAboutChancellor, getAboutChancellors } from "../../../Services/api";

var BASEDIR = process.env.REACT_APP_BASEDIR;

class AboutChancellorsListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutChancellorsData: [],
    };
    this.getAboutChancellor = this.getAboutChancellor.bind(this);
  }

  async deleteAboutChancellorsListing(id) {
    try {
      await deleteAboutChancellor({ aboutChancellorId: id });
      this.getAboutChancellor(); // Refresh the list after deletion
    } catch (error) {
      console.log("Error deleting chancellor: ", error);
    }
  }

  async getAboutChancellor() {
    try {
      const resp = await getAboutChancellors();
      this.setState({ aboutChancellorsData: resp.data.data });
    } catch (error) {
      console.log("Error fetching chancellors: ", error);
    }
  }

  componentDidMount() {
    this.getAboutChancellor();
  }

  render() {
    return (
      <div className="content">
        <Row className="mb-4">
          <Col xs={12}>
            <h1 className="title">About Chancellor/ Vice Chancellor/ Registrar / Dean / Director/ Principal/ Head / Supervisor</h1>
          </Col>
          <Col xs={12} className="d-flex justify-content-end">
            <Link to={`${BASEDIR}/crm/about-chancellors/add`}>
              <Button color="primary">Add New</Button>
            </Link>
          </Col>
        </Row>

        <Row>
          {this.state.aboutChancellorsData.map(
            ({
              _id,
              name,
              position,
              location,
              country,
              url,
              fileType,
              linkedIn,
              email,
            }) => (
              <Col md={4} sm={6} xs={12} key={_id} className="mb-4">
                <Card>
                <div className="card-image">
  {fileType.includes("image") && (
    <img
      src={url}
      alt={name}
      className="card-img-top"
      style={{
        width: "400px",
        height: "300px",
        objectFit: "contain",
        backgroundColor: "transparent", 
      }}
    />
  )}
  {fileType.includes("video") && (
    <video
      width="400"
      height="300"
      controls
      style={{
        objectFit: "contain",
      }}
    >
      <source src={url} type="video/mp4" />
    </video>
  )}
  {fileType.includes("youtube") && (
    <iframe
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      className="card-img-top"
      style={{
        width: "400px",
        height: "300px",
        objectFit: "contain", 
      }}
    />
  )}
</div>

                  <CardBody>
                    <CardTitle tag="h5">{name}</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">{position}</CardSubtitle>
                    <CardText>{location}</CardText>
                    <CardText>{country}</CardText>
                    {linkedIn && (
                      <CardText>
                        <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                          LinkedIn Profile
                        </a>
                      </CardText>
                    )}
                    {email && (
                      <CardText>
                        <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                          {email}
                        </a>
                      </CardText>
                    )}
                  </CardBody>
                  <div className="card-footer text-center">
                    <Button color="danger" onClick={() => this.deleteAboutChancellorsListing(_id)}>
                      Delete
                    </Button>
                    <Link to={`${BASEDIR}/crm/about-chancellors/edit/${_id}`}>
                      <Button color="warning" className="ml-2">
                        Edit
                      </Button>
                    </Link>
                  </div>
                </Card>
              </Col>
            )
          )}
        </Row>
      </div>
    );
  }
}

export default AboutChancellorsListings;
