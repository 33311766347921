import moment from "moment";
import React from "react";
import "./testimonial.css"
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { deleteTestimonial, getTestimonials } from "../../../Services/api";

var BASEDIR = process.env.REACT_APP_BASEDIR;

class TestimonialsListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonialsData: [],
    };
    this.getTestimonial = this.getTestimonial.bind(this);
  }

  async deleteTestimonialsListing(id) {
    console.log("delete about Testimonials listing: ", id);
    await deleteTestimonial({ testimonialId: id });
    this.getTestimonial();
  }

  async getTestimonial() {
    try {
      const resp = await getTestimonials();
      this.setState({ testimonialsData: resp.data.data });
    } catch (error) {
      console.log("Error fetching testimonials:", error);
    }
  }

  componentDidMount() {
    this.getTestimonial();
  }

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12}>
              <div className="page-title">
                <h1 className="title text-center">Testimonials</h1>
              </div>
              <div className="col-12">
                <section className="box">
                  <header className="panel_header d-flex justify-content-between align-items-center">
                    <h2 className="title">Testimonials</h2>
                    <Link to={`${BASEDIR}/crm/testimonials/add`}>
                      <button className="btn btn-primary">Add New</button>
                    </Link>
                  </header>
                  <div className="content-body">
                    <Row>
                      {this.state.testimonialsData.length > 0 ? (
                        this.state.testimonialsData.map(
                          ({ _id, name, title, rating, date, description }) => (
                            <Col md={6} lg={4} className="mb-4" key={_id}>
                              <div className="testimonial-card p-3 shadow-sm rounded">
                                <div className="testimonial-header d-flex justify-content-between align-items-center">
                                  <h5 className="mb-1">{name}</h5>
                                  <div className="rating">
                                    {Array.from({ length: rating }, (_, i) => (
                                      <i
                                        key={i}
                                        className="fa fa-star text-warning"
                                      ></i>
                                    ))}
                                  </div>
                                </div>
                                <small className="text-muted">
                                  {title} | {moment(date).format("MMMM Do, YYYY")}
                                </small>
                                <p className="mt-3">{description}</p>
                                <div className="testimonial-actions d-flex justify-content-end">
                                  <i
                                    className="fa fa-trash text-danger mr-3 pointer"
                                    onClick={() =>
                                      this.deleteTestimonialsListing(_id)
                                    }
                                  ></i>
                                  <Link to={`${BASEDIR}/crm/testimonials/edit/${_id}`}>
                                    <i className="fa fa-edit text-primary pointer"></i>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          )
                        )
                      ) : (
                        <Col xs={12}>
                          <p className="text-center text-muted">
                            No testimonials available. Add a new one!
                          </p>
                        </Col>
                      )}
                    </Row>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default TestimonialsListings;
