import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { deleteAwardsAndRecognition, getAwardsAndRecognitions } from "../../../Services/api";
import './AwardsAndRecognitions.css'; 

var BASEDIR = process.env.REACT_APP_BASEDIR;

class AwardsAndRecognitionsListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      awardsAndRecognitionsData: [],
    };
    this.getAwardsAndRecognition = this.getAwardsAndRecognition.bind(this);
  }

  async deleteAwardsAndRecognitionsListing(id, index) {
    console.log("delete about AwardsAndRecognitions listing: ", id);
    try {
      await deleteAwardsAndRecognition({ awardsAndRecognitionId: id });
      // Remove item from state without fetching again
      const updatedData = this.state.awardsAndRecognitionsData.filter((item) => item._id !== id);
      this.setState({ awardsAndRecognitionsData: updatedData });
    } catch (error) {
      console.error("Error deleting award: ", error);
    }
  }

  async getAwardsAndRecognition() {
    try {
      const resp = await getAwardsAndRecognitions();
      this.setState({ awardsAndRecognitionsData: resp.data.data });
    } catch (error) {
      console.log("Error fetching awards: ", error);
    }
  }

  componentDidMount() {
    this.getAwardsAndRecognition();
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <div className="page-title">
              <h1 className="title">Awards And Recognitions</h1>
            </div>

            <section className="box">
              <header className="panel_header">
                <h2 className="title float-left">Awards And Recognitions</h2>
                <Link to={`${BASEDIR}/crm/awards-and-recognitions/add`}>
                  <button className="btn btn-primary">Add New</button>
                </Link>
              </header>

              <div className="content-body">
                <Row className="award-list">
                  {this.state.awardsAndRecognitionsData.map(({ _id, title, url, fileType }) => (
                    <Col md={4} sm={4} xs={12} key={_id} className="award-card">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">{title}</h5>
                          <div className="media-container">
                            {fileType.includes("image") && (
                              <img className="media-img" alt={title} src={url} />
                            )}
                            {fileType.includes("video") && (
                              <video className="media-video" controls>
                                <source src={url} type="video/mp4" />
                              </video>
                            )}
                            {fileType.includes("youtube") && (
                              <iframe
                                src={url}
                                frameBorder="0"
                                allowFullScreen
                                title="Embedded YouTube"
                                className="media-iframe"
                              />
                            )}
                          </div>
                          <div className="card-actions">
                            <i
                              className="i-trash icon-xs pointer"
                              onClick={() => this.deleteAwardsAndRecognitionsListing(_id)}
                            ></i>
                            <Link to={`${BASEDIR}/crm/awards-and-recognitions/edit/${_id}`}>
                              <i className="i-note icon-xs pointer"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </section>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AwardsAndRecognitionsListings;
