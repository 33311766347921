import moment from "moment";
import React from "react";
import { Row, Col, Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { deleteTeacher, getTeachers } from "../../../Services/api";

var BASEDIR = process.env.REACT_APP_BASEDIR;

class AboutTeachersListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teachersData: [],
    };
    this.getTeacher = this.getTeacher.bind(this);
  }

  async deleteAboutTeachersListing(id) {
    console.log("delete about teachers listing: ", id);
    await deleteTeacher({ teacherId: id });
    this.getTeacher();
  }

  async getTeacher() {
    try {
      const resp = await getTeachers();
      this.setState({ teachersData: resp.data.data });
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  componentDidMount() {
    this.getTeacher();
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">
                  About Teachers / Lecturers / Administrators Listing
                </h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box">
                <header className="panel_header align-items-center row justify-content-between">
                  <h2 className="title float-left">
                    All About Teachers / Lecturers / Administrators Listing
                  </h2>
                  <Link to={`${BASEDIR}/crm/about-teachers/add`}>
                    <Button color="primary">Add New</Button>
                  </Link>
                </header>
                <div className="content-body">
                  <div className="row">
                    {this.state.teachersData.map(
                      ({
                        _id,
                        name,
                        position,
                        location,
                        country,
                        url,
                        fileType,
                        linkedIn,
                      }) => (
                        <Col lg="4" md="6" sm="12" key={_id}>
                          <Card className="my-3">
                            <div className="image-container">
                              {fileType.includes("image") && (
                                <img
                                  src={url}
                                  alt={name}
                                  className="card-img-top"
                                  style={{
                                    width: "400px",
                                    height: "300px",
                                    objectFit: "contain", 
                                    backgroundColor: "transparent", 
                                  }}
                                />
                              )}
                              {fileType.includes("video") && (
                                <video
                                  src={url}
                                  controls
                                  className="card-img-top"
                                  style={{ height: "200px",  }}
                                />
                              )}
                              {fileType.includes("youtube") && (
                                <iframe
                                  src={url}
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                  title="Embedded youtube"
                                  className="card-img-top"
                                  style={{
                                    height: "200px",
                                    objectFit: "cover",
                                    width: "100%",
                                  }}
                                />
                              )}
                            </div>
                            <CardBody>
                              <CardTitle tag="h5">{name}</CardTitle>
                              <CardText>
                                <strong>Position:</strong> {position}
                              </CardText>
                              <CardText>
                                <strong>Location:</strong> {location}, {country}
                              </CardText>
                              {linkedIn && (
                                <CardText>
                                  <strong>LinkedIn:</strong>{" "}
                                  <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                                    View Profile
                                  </a>
                                </CardText>
                              )}
                              <div className="d-flex justify-content-between">
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => this.deleteAboutTeachersListing(_id)}
                                >
                                  Delete
                                </Button>
                                <Link to={`${BASEDIR}/crm/about-teachers/edit/${_id}`}>
                                  <Button color="warning" size="sm">
                                    Edit
                                  </Button>
                                </Link>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    )}
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AboutTeachersListings;
