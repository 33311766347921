import React, { Component } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FaUser, FaEnvelope, FaDownload, FaTrash } from "react-icons/fa";
import classnames from "classnames";
import { getAllUsers, deleteUserById } from "../../../Services/api";

class UsersListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsersData: [],
      activeTab: "student",
      showModal: false,
      selectedUser: null,
    };
  }

  async getUsers() {
    try {
      const resp = await getAllUsers();
      if (Array.isArray(resp.data.data)) {
        this.setState({ allUsersData: resp.data.data });
      } else {
        console.error("Expected an array but got:", resp.data.data);
      }
    } catch (error) {
      console.log("Error fetching users:", error);
    }
  }
  handleDownload = (resumeUrl) => {
    if (!resumeUrl) {
      alert("Resume not available.");
      return;
    }
    const link = document.createElement("a");
    link.href = resumeUrl;
    link.download = "resume.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async handleDeleteUser(emailId) {
    try {
      const response = await deleteUserById(emailId);
      if (response && response.data && response.data.success === 1) {
        this.setState((prevState) => ({
          allUsersData: prevState.allUsersData.filter(
            (user) => user.email !== emailId
          ),
        }));
        alert(`User with email ${emailId} has been successfully deleted.`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        console.error("Error deleting user:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  toggleModal = (user = null) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      selectedUser: user,
    }));
  };

  componentDidMount() {
    this.getUsers();
  }

  renderUserCard(user) {
    const { userId, firstName, lastName, email, resume } = user;
    return (
      <Col sm="4" key={userId} className="mb-4">
        <Card className="custom-card">
          <CardBody className="d-flex flex-column">
            <div className="d-flex align-items-center mb-2 custom-username">
              <FaUser
                className="me-2 custom-icon-user"
                style={{ color: "#B22222" }}
              />
              <CardTitle>{`${firstName} ${lastName}`}</CardTitle>
            </div>
            <div className="d-flex align-items-center mb-2 custom-email">
              <FaEnvelope
                className="me-2 custom-icon-email"
                style={{ color: "#B22222" }}
              />
              <CardSubtitle>{email}</CardSubtitle>
            </div>
            {resume && (
              <div>
                <CardText className="custom-resume-text"></CardText>
              </div>
            )}
            <div className="mt-auto d-flex justify-content-between">
              <Button
                color="info"
                size="sm"
                onClick={() => this.toggleModal(user)}
              >
                View Profile
              </Button>
              <Button
                color="danger"
                size="sm"
                onClick={() => this.handleDeleteUser(email)}
              >
                <FaTrash className="me-1" />
                Delete
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderModal() {
    const { showModal, selectedUser } = this.state;
    if (!selectedUser) return null;

    const {
      firstName,
      lastName,
      email,
      phone,
      age,
      city,
      pincode,
      experience,
      url,
      address,
      board,
      preference,
    } = selectedUser;

    return (
      <Modal isOpen={showModal} toggle={this.toggleModal}>
        <ModalHeader
          toggle={this.toggleModal}
          style={{ backgroundColor: "#26a69a" }}
        >
          <span style={{ color: "#ffffff" }}>{`${firstName} ${lastName}`}</span>
        </ModalHeader>

        <ModalBody>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={url}
              alt="Profile"
              style={{
                maxWidth: "30%",
                height: "auto",
                borderRadius: "10px",
                marginRight: "20px",
              }}
            />
            <div>
              <p>
                <strong>Email:</strong> {email}
              </p>
              <p>
                <strong>Phone:</strong> {phone}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
            }}
          >
            <div>
              <p>
                <strong>Age:</strong> {age}
              </p>
              <p>
                <strong>Address:</strong> {address}
              </p>
              <p>
                <strong>City:</strong> {city}
              </p>
              <p>
                <strong>Pincode:</strong> {pincode}
              </p>
            </div>
            <div>
              <p>
                <strong>Experience:</strong> {experience} years
              </p>
              <p>
                <strong>Board:</strong> {board}
              </p>
              <p>
                <strong>Preference:</strong> {preference}
              </p>
            </div>
          </div>

          {selectedUser.resume && (
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <Button
                color="primary"
                size="sm"
                onClick={() => this.handleDownload(selectedUser.resume)}
              >
                <FaDownload className="me-1" />
                Download Resume
              </Button>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { allUsersData, activeTab } = this.state;
    const groupedUsers = {};
    if (Array.isArray(allUsersData)) {
      allUsersData.forEach((user) => {
        const { userType } = user;
        if (userType && typeof userType === "string" && userType.trim() !== "") {
          if (!groupedUsers[userType]) {
            groupedUsers[userType] = [];
          }
          groupedUsers[userType].push(user);
        } else {
          console.warn("Invalid userType found:", user); 
        }
      });
    }
    const tabItems = Object.keys(groupedUsers).map((userType) => (
      <NavItem key={userType}>
        <NavLink
          className={classnames({ active: activeTab === userType })}
          onClick={() => this.toggleTab(userType)}
        >
          {userType}
        </NavLink>
      </NavItem>
    ));
    const tabContent = Object.keys(groupedUsers).map((userType) => (
      <TabPane key={userType} tabId={userType}>
        <Row>
          {groupedUsers[userType].map((user) => this.renderUserCard(user))}
        </Row>
      </TabPane>
    ));
  
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Users</h1>
                </div>
              </div>
              <div className="col-12">
                <section className="box">
                  <header className="panel_header align-items-center row justify-content-between">
                    <h2 className="title float-left">Users</h2>
                  </header>
                  <div className="content-body">
                    <div>
                      <div className="col-lg-12 row">
                        <Nav tabs>{tabItems}</Nav>
                      </div>
                      <TabContent activeTab={activeTab}>{tabContent}</TabContent>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
        {this.renderModal()}
      </div>
    );
  }
  
}  

export default UsersListings;
