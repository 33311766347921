import React from "react";
import { Row, Col, Button, Card, CardBody, CardImg, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import { deleteFeaturedList, getFeaturedLists } from "../../../Services/api";

const BASEDIR = process.env.REACT_APP_BASEDIR;

class FeaturedListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      featuredListsData: [],
    };
    this.getFeaturedList = this.getFeaturedList.bind(this);
  }

  async deleteFeaturedListing(id) {
    console.log("delete Featured Listing: ", id);
    await deleteFeaturedList({ featuredListId: id });
    this.getFeaturedList();
  }

  async getFeaturedList() {
    try {
      const resp = await getFeaturedLists();
      this.setState({ featuredListsData: resp.data.data });
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  componentDidMount() {
    this.getFeaturedList();
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <div className="page-title">
              <h1 className="title">Featured Listings</h1>
            </div>

            <div className="mb-4 d-flex justify-content-between align-items-center">
              <h2 className="title">All Featured Listings</h2>
              <Link to={`${BASEDIR}/crm/featured-listings/add`}>
                <Button color="primary">Add New</Button>
              </Link>
            </div>

            <Row>
              {this.state.featuredListsData.length === 0 ? (
                <Col xs={12}>
                  <p className="text-center">No featured listings available.</p>
                </Col>
              ) : (
                this.state.featuredListsData.map(({ _id, url, fileType }) => (
                  <Col lg={4} md={6} sm={12} key={_id} className="mb-4">
                    <Card className="h-100 shadow-sm">
                      <CardBody>
                        {fileType.includes("image") && (
                          <CardImg
                            top
                            src={url}
                            alt="Featured Listing"
                            className="rounded mb-3"
                          />
                        )}
                        {fileType.includes("video") && (
                          <video
                            src={url}
                            controls
                            className="w-100 mb-3"
                          ></video>
                        )}
                        {fileType.includes("youtube") && (
                          <iframe
                            src={url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                            className="w-100 rounded mb-3"
                          ></iframe>
                        )}
                        {/* <CardText className="text-muted">
                          ID: {_id}
                        </CardText> */}
                        <div className="d-flex justify-content-between">
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => this.deleteFeaturedListing(_id)}
                          >
                            Delete
                          </Button>
                          <Link to={`${BASEDIR}/crm/featured-listings/edit/${_id}`}>
                            <Button color="secondary" size="sm">
                              Edit
                            </Button>
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default FeaturedListings;
