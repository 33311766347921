import React from "react";
import moment from "moment";
import "./testimonial.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { deleteAd, getAllAds } from "../../../Services/api";

var BASEDIR = process.env.REACT_APP_BASEDIR;

class AddEditAdslist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adsData: [],
      isLoading: true,
      error: null,
    };
    this.fetchAds = this.fetchAds.bind(this);
    this.deleteAdHandler = this.deleteAdHandler.bind(this);
  }

  async fetchAds() {
    try {
      const response = await getAllAds();
      if (response && response.data) {
        this.setState({ adsData: response.data.data || [], isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching ads:", error);
      this.setState({ error: "Failed to fetch ads.", isLoading: false });
    }
  }

  async deleteAdHandler(adId) {
    try {
      await deleteAd(adId); // Pass the ID directly
      this.fetchAds(); // Refresh ads after deletion
    } catch (error) {
      console.error("Error deleting ad:", error);
      alert("Failed to delete ad. Please try again.");
    }
  }

  componentDidMount() {
    this.fetchAds();
  }

  render() {
    const { adsData, isLoading, error } = this.state;

    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <div className="page-title">
              <h1 className="title text-center">Ads List</h1>
            </div>
            <div className="col-12">
              <section className="box">
                <header className="panel_header d-flex justify-content-between align-items-center">
                  <h2 className="title">Ads List</h2>
                  <Link to={`${BASEDIR}/crm/ads/add`}>
                    <button className="btn btn-primary">Add New</button>
                  </Link>
                </header>
                <div className="content-body">
                  {isLoading ? (
                    <p className="text-center text-muted">Loading ads...</p>
                  ) : error ? (
                    <p className="text-center text-danger">{error}</p>
                  ) : adsData.length > 0 ? (
                    <Row>
                      {adsData.map(
                        ({
                          _id,
                          targetAudience,
                          link,
                          image,
                          isActive,
                          createdAt,
                        }) => (
                          <Col md={6} lg={4} className="mb-4" key={_id}>
                            <div className="ad-card p-3 shadow-sm rounded">
                              <div className="ad-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-1">{targetAudience}</h5>
                                <small
                                  className={
                                    isActive ? "text-success" : "text-danger"
                                  }
                                >
                                  {isActive ? "Active" : "Inactive"}
                                </small>
                              </div>
                              <div className="image-container my-2">
                                {image && (
                                  <img
                                    src={image}
                                    alt={targetAudience}
                                    className="img-fluid rounded"
                                    style={{
                                      width: "400px",
                                      height: "300px",
                                      objectFit: "contain", 
                                      backgroundColor: "transparent", 
                                    }}
                                  />
                                )}
                              </div>
                              <p className="mt-2">
                                <a
                                  href={link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {link}
                                </a>
                              </p>
                              <small className="text-muted">
                                Created on:{" "}
                                {moment(createdAt).format("MMMM Do, YYYY")}
                              </small>
                              <div className="ad-actions d-flex justify-content-end mt-3">
                                <i
                                  className="fa fa-trash text-danger mr-3 pointer"
                                  onClick={() => this.deleteAdHandler(_id)}
                                ></i>
                                <Link to={`${BASEDIR}/crm/ads/edit/${_id}`}>
                                  <i className="fa fa-edit text-primary pointer"></i>
                                </Link>
                              </div>
                            </div>
                          </Col>
                        )
                      )}
                    </Row>
                  ) : (
                    <p className="text-center text-muted">
                      No ads available. Add a new one!
                    </p>
                  )}
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddEditAdslist;
