import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { deleteBlogs, deleteEvents } from "../../../Services/api";

var BASEDIR = process.env.REACT_APP_BASEDIR;

class BlogBlogPosts extends React.Component {
  async deleteBlog(id) {
    try {
      await deleteBlogs({ blogId: id });
      this.props.refreshBlog();
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  async deleteEvent(id) {
    try {
      await deleteEvents({ eventId: id });
      this.props.refreshBlog();
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  render() {
    let blogsList = [];
    for (let i = 0; i < this.props.blogs.length; i++) {
      blogsList.push(
        <div className="col-12 col-md-6 col-lg-4" key={i}>
          <div className="card shadow-sm mb-4">
            <img
              src={this.props.blogs[i].image}
              className="card-img-top"
              alt={this.props.blogs[i].title}
              style={{ height: "400px",objectFit:'cover', }}
            />
            <div className="card-body">
              <h5 className="card-title text-truncate" title={this.props.blogs[i].title}>
                {this.props.blogs[i].title}
              </h5>
              {!this.props.event && (
                <p className="text-muted mb-2">
                  Written by <span className="text-primary">{this.props.blogs[i].author}</span>
                </p>
              )}
              <p className="card-text text-truncate" title={this.props.blogs[i].description}>
                {this.props.blogs[i].description}
              </p>
            </div>
            <div className="card-footer d-flex justify-content-between align-items-center">
              <NavLink
                to={{
                  pathname: this.props.event
                    ? BASEDIR + "/crm/events/edit"
                    : BASEDIR + "/crm/blogs/edit",
                  state: {
                    selectedBlog: this.props.blogs[i],
                  },
                }}
                className="btn btn-sm btn-primary"
              >
                Edit
              </NavLink>
              <button
                className="btn btn-sm btn-danger"
                onClick={() =>
                  this.props.event
                    ? this.deleteEvent(this.props.blogs[i]._id)
                    : this.deleteBlog(this.props.blogs[i]._id)
                }
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      );
    }
    return <div className="row">{blogsList}</div>;
  }
}

BlogBlogPosts.propTypes = {
  blogs: PropTypes.arrayOf(PropTypes.object),
};

export default BlogBlogPosts;
