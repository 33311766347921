import React from "react";
import { Row, Col, Label, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import { createAd, getAdById, updateAd } from "../../../Services/api";

class AddEditAds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      adData: null,
      previewImage: null, // Holds the preview URL of the uploaded image
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;
    if (id) {
      await this.getAdForEdit(id);
    } else {
      this.setState({
        adData: {
          targetAudience: "",
          image: "",
          link: "",
          isActive: true,
        },
        previewImage: null,
      });
    }
  }

  async getAdForEdit(id) {
    try {
      const adResp = await getAdById(id);
      this.setState({
        id,
        adData: adResp.data.data,
        previewImage: adResp.data.data.image, // Assuming `image` holds the URL to the existing image
      });
    } catch (error) {
      console.error("Error fetching ad for edit:", error);
    }
  }

  async saveAd(values) {
    const { id } = this.state;
    const formData = new FormData();
    formData.append("targetAudience", values.targetAudience);
    formData.append("link", values.link);
    formData.append("isActive", values.isActive);
    if (values.image instanceof File) {
      formData.append("image", values.image);
    }

    try {
      if (id) {
        await updateAd(id, formData); 
      } else {
        await createAd(formData); 
      }
      this.props.history.push("/crm/ads/list"); 
    } catch (error) {
      console.error("Error saving ad:", error);
    }
  }

  render() {
    const { adData, previewImage } = this.state;

    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <h1 className="title">{this.state.id ? "Edit Ad" : "Create Ad"}</h1>
            {adData && (
              <Formik
                initialValues={adData}
                validate={(values) => {
                  const errors = {};
                  if (!values.targetAudience) {
                    errors.targetAudience = "Target Audience is required";
                  }
                  if (!values.link) {
                    errors.link = "Link is required";
                  }
                  if (!this.state.id && !values.image) {
                    errors.image = "Image is required for new ads";
                  }
                  return errors;
                }}
                onSubmit={(values) => this.saveAd(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <Label>Target Audience</Label>
                      <Input
                        type="text"
                        name="targetAudience"
                        value={values.targetAudience}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.targetAudience && touched.targetAudience
                            ? "is-invalid"
                            : ""
                        }
                      />
                      {errors.targetAudience && touched.targetAudience && (
                        <div className="invalid-feedback">
                          {errors.targetAudience}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <Label>Ad Link</Label>
                      <Input
                        type="url"
                        name="link"
                        value={values.link}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.link && touched.link ? "is-invalid" : ""
                        }
                      />
                      {errors.link && touched.link && (
                        <div className="invalid-feedback">{errors.link}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <Label>Image</Label>
                      {previewImage && (
                        <div className="mb-2">
                          <img
                            src={previewImage}
                            alt="Preview"
                            style={{ maxWidth: "200px", height: "auto" }}
                          />
                        </div>
                      )}
                      <Input
                        type="file"
                        name="image"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("image", file);
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = () => {
                              this.setState({ previewImage: reader.result });
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                        className={
                          errors.image && touched.image ? "is-invalid" : ""
                        }
                      />
                      {errors.image && touched.image && (
                        <div className="invalid-feedback">{errors.image}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <Label>Active Status</Label>
                      <Input
                        type="checkbox"
                        name="isActive"
                        checked={values.isActive}
                        onChange={handleChange}
                      />
                    </div>

                    <button type="submit" className="btn btn-primary">
                      {this.state.id ? "Update Ad" : "Create Ad"}
                    </button>
                  </form>
                )}
              </Formik>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddEditAds;
