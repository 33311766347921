import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { Navmenudropdown, Navmenugroup } from "components"; // Ensure these components exist
import PerfectScrollbar from "perfect-scrollbar";
import "./Sidebar.css";

var ps;
var IMGDIR = process.env.REACT_APP_IMGDIR;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opendd: "",
      currentmenu: "notset", // Added currentmenu state here
      profilename: "Eric Nelson",
      profileimg: IMGDIR + "/images/profile/profile.jpg",
      profileposition: "Web Developer",
    };
    this.handleOpendd = this.handleOpendd.bind(this);
  }

  handleOpendd(open) {
    this.setState((prevState) => ({
      // opendd: prevState.opendd === open ? "" : open,
      opendd: open,
      currentmenu: prevState.opendd === open ? "" : open, // Set currentmenu when toggling dropdown
    }));
  }

  activeRoute(routeName) {
    console.log(this.props.location.pathname.indexOf(routeName));
    return this.props.location.pathname.indexOf(routeName) > -1 ? " active" : "";
  }

  componentDidMount() {
    if (this.props.admintype === "general") {
      this.setState({
        profileposition: "Web Developer",
        profileimg: IMGDIR + "/images/profile/profile-general.jpg",
        profilename: "Nancy Spencer",
      });
    } else if (this.props.admintype === "hospital") {
      this.setState({
        profileposition: "Surgeon",
        profileimg: IMGDIR + "/images/profile/profile-hospital.jpg",
        profilename: "Dianna Austin",
      });
    } else if (this.props.admintype === "university") {
      this.setState({
        profileposition: "Professor",
        profilename: "Henry Gibson",
        profileimg: IMGDIR + "/images/profile/profile-university.jpg",
      });
    } else if (this.props.admintype === "crm") {
      this.setState({
        profileposition: "Super Admin",
        profilename: "Eduwize Admin",
        profileimg: IMGDIR + "/images/profile/profile-crm.jpg",
      });
    } else if (this.props.admintype === "music") {
      this.setState({
        profileposition: "Singer",
        profilename: "Kerry Flores",
        profileimg: IMGDIR + "/images/profile/profile-music.jpg",
      });
    } else if (this.props.admintype === "blog") {
      this.setState({
        profileposition: "Editor",
        profilename: "Alice Gross",
        profileimg: IMGDIR + "/images/profile/profile-blog.jpg",
      });
    } else if (this.props.admintype === "ecommerce") {
      this.setState({
        profileposition: "Vendor",
        profilename: "Jake Daniel",
        profileimg: IMGDIR + "/images/profile/profile-ecommerce.jpg",
      });
    } else if (this.props.admintype === "freelance") {
      this.setState({
        profileposition: "Designer",
        profilename: "Eric Nelson",
        profileimg: IMGDIR + "/images/profile/profile-freelance.jpg",
      });
    } else if (this.props.admintype === "social") {
      this.setState({
        profileposition: "Artist",
        profilename: "Penny Taylor",
        profileimg: IMGDIR + "/images/profile/profile-social.jpg",
      });
    } else {
      this.setState({
        profileposition: "Web Developer",
        profilename: "Nancy Spencer",
        profileimg: IMGDIR + "/images/profile/profile-general.jpg",
      });
    }

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const children = (child, parent) => {
      if (!child) return null;

      return (
        <Nav>
          {child.map((item, i) => (
            <li key={i}>
              <NavLink
                to={item.path}
                className="nav-link"
                activeClassName="active"
              >
                <span>{item.name}</span>
              </NavLink>
            </li>
          ))}
        </Nav>
      );
    };

    return (
      <div className="sidebar menubar" data-color="black">
        <div className="logo">
          <a href="/" className="logo-mini">
            <h3>EAP</h3>
          </a>
          <a href="/" className="logo-full">
            <h3>Admin Panel</h3>
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <div className="profile-info row">
            <div className="profile-image col-4">
              <a href="#!">
                <img
                  alt=""
                  src={this.state.profileimg}
                  className="img-fluid avatar-image"
                />
              </a>
            </div>
            <div className="profile-details col-8">
              <h3>
                <a href="#!" className="profile-name">
                  {this.state.profilename}
                </a>
                <span className="profile-status online profile-title"></span>
              </h3>
              <p className="profile-title">{this.state.profileposition}</p>
            </div>
          </div>

          <Nav className="navigation">
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;
              if (prop.type === "child") return null;
              // if(!prop.show) return null;
              if (prop.type === "navgroup")
                return <Navmenugroup name={prop.name} key={key}></Navmenugroup>;
              if (prop.type === "dropdown")
                return (
                  <li
                    key={key}
                    className={`nav-parent ${this.state.opendd === prop.name || this.state.currentmenu === prop.parentid ? "active" : ""}`}
                    onClick={() => this.handleOpendd(prop.name)}
                  >
                    <a href="#!" className="nav-link">
                      <i className={`i-${prop.icon}`}></i>
                      <p>{prop.name}</p>
                      <span className="badge">{prop.badge}</span>
                      <span className="arrow i-arrow-left"></span>
                    </a>
                    {children(prop.child, prop.parentid)}
                  </li>
                );
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
